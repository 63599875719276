import {
	AccountData,
	CardData,
	ApiStatus,
	CreateCardRequest,
	LoginData,
	LoginRequest,
	LoginResponse,
	LoginType,
	PagedData,
	TransactionData,
	CardLimits,
	CardServices,
	CardServicesRequest,
	UserData,
	BasicCardOperationResponse,
	CardProgramResponse,
	CardStatus,
	DisputeRequest,
	InitiateChangePasswordResponse,
	ChangePasswordResponse, Corporation, CorporationMember,
} from './Models'
import { clearSession, getCurrentUserId, getTenantId, setCurrentUserId, setTenantId } from './Session'
import * as process from 'process'
import { jwtDecode } from 'jwt-decode'

const REALM_NAME = process.env.REACT_APP_REALM_NAME as string
const CUSTOMER_API = process.env.REACT_APP_CUSTOMER_API as string
const USERS_API_URL = process.env.REACT_APP_USERS_API_URL as string
const CORPORATIONS_API_URL = process.env.REACT_APP_CORPORATIONS_API_URL as string
const CARDS_API_URL = process.env.REACT_APP_CARDS_API_URL as string
const DISPUTES_API_URL = process.env.REACT_APP_DISPUTES_API_URL as string
const ACCOUNTS_API_URL = process.env.REACT_APP_ACCOUNTS_API_URL as string
const TENANT_API_URL = process.env.REACT_APP_TENANT_API_URL as string

interface ApiResult<ResponseT> {
	success: boolean
	data?: ResponseT
	code: number
	error?: string
}

enum FilterOperation {
	Equal = 'Equal',
	NotEqual = 'NotEqual',
	Greater = 'Greater',
	Less = 'Less',
	GreaterOrEqual = 'GreaterOrEqual',
	LessOrEqual = 'LessOrEqual',
	Contains = 'Contains',
	NotContains = 'NotContains',
	StartsWith = 'StartsWith',
	EndsWith = 'EndsWith',
	NotStartsWith = 'NotStartsWith',
	NotEndsWith = 'NotEndsWith',
}

enum TransactionType {
	Pos = 'Pos',
	Ecommerce = 'Ecommerce',
	Atm = 'Atm',
	PosCash = 'PosCash',
	Fee = 'Fee',
	AnnualFee = 'AnnualFee',
	Manual = 'Manual',
	Preauthorization = 'Preauthorization',
	PinChange = 'PinChange',
	Balance = 'Balance',
	FastCash = 'FastCash',
	PinSelection = 'PinSelection',
	PaperPin = 'PaperPin',
}

enum ProcessingTypeRequest {
	Debit = 'Debit',
	Credit = 'Credit',
	DebitReversal = 'DebitReversal',
	CreditReversal = 'CreditReversal',
}

enum AccountType {
	Current = 'Current',
	Personal = 'Personal',
	StaffCurrent = 'StaffCurrent',
	Card = 'Card',
	FxTransit = 'FxTransit',
	LoanPayment = 'LoanPayment',
	PrepaidCard = 'PrepaidCard',
	CashDesk = 'CashDesk',
}

export interface TransactionFilter {
	OrderBy?: string
	OrderByDescending?: boolean
	'OffsetPaging.Skip'?: number
	'OffsetPaging.Take'?: number
	'CreatedOn.StartDate'?: string
	'CreatedOn.EndDate'?: string
	TransactionType?: TransactionType
	TransactionId?: string
	ProcessingType?: ProcessingTypeRequest
	Id?: string
	TenantId?: string
	UserId?: string
	CardId?: string
	AccountId?: string
	MerchantId?: string
	'Amount.Value'?: number
	'Amount.Operation'?: FilterOperation
	Currency?: string
	TransactionStatus?: string
	'ReferenceNumber.Value'?: string
	'ReferenceNumber.Operation'?: FilterOperation
	'MerchantCategoryCode.Value'?: string
	'MerchantCategoryCode.Operation'?: FilterOperation
	'SettlementDate.StartDate'?: string
	'SettlementDate.EndDate'?: string
	SendSms?: boolean
}

const put = async <RequestT, ResponseT>(
	route: string,
	data: RequestT | null,
	extraHeaders?: any
): Promise<ApiResult<ResponseT>> => {
	const headers: any = {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	}

	if (extraHeaders) {
		for (let key in extraHeaders) {
			headers[key] = extraHeaders[key]
		}
	}

	const requestOptions = {
		method: 'PUT',
		headers: headers,
		body: JSON.stringify(data),
	}

	const token = localStorage.getItem('token')
	if (token) {
		requestOptions.headers = { ...requestOptions.headers, Authorization: `Bearer ${token}` }
	}

	const response = fetch(route, requestOptions)
		.then(async (response) => {
			return getResponse<ResponseT>(response)
		})
		.catch((error) => {
			console.error('Error:', error)
			return {
				success: false,
				error: error.toString(),
			}
		})

	return response as Promise<ApiResult<ResponseT>>
}

const post = async <RequestT, ResponseT>(
	route: string,
	data: RequestT | null,
	extraHeaders?: any
): Promise<ApiResult<ResponseT>> => {
	const headers: any = {
		'Content-Type': 'application/json-patch+json',
		'Access-Control-Allow-Origin': '*',
	}

	if (extraHeaders) {
		for (let key in extraHeaders) {
			headers[key] = extraHeaders[key]
		}
	}

	const requestOptions = {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data),
	}

	if (route !== `${USERS_API_URL}/change-password`) {
		const token = localStorage.getItem('token')
		if (token) {
			requestOptions.headers.Authorization = `Bearer ${token}`
			requestOptions.headers = { ...requestOptions.headers, Authorization: `Bearer ${token}` }
		}
	}

	const response = fetch(route, requestOptions)
		.then(async (response) => {
			return getResponse<ResponseT>(response)
		})
		.catch((error) => {
			console.error('Error:', error)
			return {
				success: false,
				error: error.toString(),
			}
		})

	return response as Promise<ApiResult<ResponseT>>
}

const getResponse = async <T,>(
	response: Response,
	specialJsonTreatment?: (i: string) => string
): Promise<ApiResult<T>> => {
	if (response.status === 401) {
		clearSession()
		localStorage.removeItem('token')
		window.location.href = `/?callback=${window.location.pathname}${window.location.search}`
		return {
			success: false,
			code: 403,
			error: 'Unauthorized',
		}
	}

	if (response.status === 400) {
		const error400 = await response.json()

		if (error400.data.length > 0) {
			if (error400.data[0].reason) {
				return {
					success: false,
					code: error400.data[0].code as number,
					error: error400.data[0].reason as string,
				}
			}

			return {
				success: false,
				code: error400.data[0].code,
				error: error400.data[0].code.name as string,
			}
		}

		return {
			success: false,
			code: 400,
			error: JSON.stringify(error400),
		}
	}

	if (!response.ok) {
		return {
			success: false,
			code: response.status,
			error: `Unknown failure ${response.status}`,
		}
	}

	if (specialJsonTreatment) {
		let text = await response.text()
		text = specialJsonTreatment(text)
		return {
			success: true,
			code: 200,
			data: JSON.parse(text).data as T,
		}
	}

	let text = await response.text()

	if (!text) {
		return {
			success: true,
			code: 200,
		}
	}

	const json = JSON.parse(text)

	return {
		success: true,
		code: 200,
		data: json.data as T,
	}
}

const get = async <ResponseT,>(
	route: string,
	params?: any | null,
	headers?: any | null,
	specialJsonTreatment?: (i: string) => string
): Promise<ApiResult<ResponseT>> => {
	const requestOptions = {
		method: 'GET',
		headers: {
			...headers,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
	}

	const token = localStorage.getItem('token')
	if (token) {
		requestOptions.headers = { ...requestOptions.headers, Authorization: `Bearer ${token}` }
	}

	const url = new URL(route)

	if (params) {
		for (let key in params) {
			if (params[key] === undefined) {
				continue
			}

			if (Array.isArray(params[key])) {
				const len = params[key].length
				for (let i = 0; i < len; i++) {
					url.searchParams.append(key, params[key][i].toString())
				}
			} else {
				url.searchParams.append(key, params[key].toString())
			}
		}
	}

	const response = fetch(url.toString(), requestOptions)
		.then(async (response) => {
			return getResponse<ResponseT>(response, specialJsonTreatment)
		})
		.catch((error) => {
			console.error('Error:', error)
			return {
				success: false,
				error: error.toString(),
			}
		})

	return response as Promise<ApiResult<ResponseT>>
}

const defaultCardStatuses: CardStatus[] = [
	CardStatus.AwaitingApproval,
	//CardStatus.Requested,
	//CardStatus.InActive,
	CardStatus.Active,
	CardStatus.BlockedByCustomer,
	CardStatus.BlockedByCreditor,
	CardStatus.BlockedByIssuer,
	CardStatus.BlockedByCardProcessor,
	CardStatus.Lost,
	CardStatus.Stolen,
	CardStatus.Damaged,
	CardStatus.Closed,
	CardStatus.Renewed,
	CardStatus.Expired,
	CardStatus.Misappropriation,
	//CardStatus.UnauthorizedUse,
]

class Api {
	readonly Cards = {
		CreateCard: async (cardRequest: CreateCardRequest): Promise<ApiResult<any>> => {
			return post(`${CARDS_API_URL}/new`, cardRequest)
		},
		IssueCard: async (cardRequest: CreateCardRequest): Promise<ApiResult<any>> => {
			return post(`${CARDS_API_URL}/issue`, cardRequest)
		},
		GetCards: async (filters?: any): Promise<ApiResult<PagedData<CardData>>> => {
			if (!filters || !('OrderBy' in filters)) {
				filters = { ...filters, OrderBy: 'createdOn' }
			}
			//return get(`${CARDS_API_URL}`, { ...filters, "Filters.Statuses": defaultCardStatuses });
			return get(`${CARDS_API_URL}`, filters)
		},
		GetCardsWithBalance: async (filters?: any): Promise<ApiResult<PagedData<CardData>>> => {
			return API.Cards.GetCards({ ...filters, withBalance: true })
		},
		GetCardUsageLimits: async (cardId: string): Promise<ApiResult<CardLimits>> => {
			return get(`${CARDS_API_URL}/${cardId}/card-limits`)
		},
		SetCardUsageLimits: async (cardId: string, limits: CardLimits): Promise<ApiResult<any>> => {
			return put(`${CARDS_API_URL}/${cardId}/card-limits`, limits)
		},
		GetCardServices: async (cardId: string): Promise<ApiResult<CardServices>> => {
			const headers = {
				'x-user-id': getCurrentUserId(),
				'x-realm-id': getTenantId(),
			}
			return get(`${CARDS_API_URL}/${cardId}/toggle-services`, null, headers)
		},
		SetCardServices: async (cardId: string, request: CardServicesRequest): Promise<ApiResult<CardServices>> => {
			return put(`${CARDS_API_URL}/${cardId}/toggle-services`, request)
		},
		SetCardServicesTest: async (cardId: string, request: CardServices): Promise<ApiResult<CardServices>> => {
			return put(`${CARDS_API_URL}/${cardId}/toggle-services`, request)
		},
		GetUserCards: async (): Promise<ApiResult<any>> => {
			const currentUserId = getCurrentUserId().toString()
			return get(`${CARDS_API_URL}/transactions`, {
				'Filters.UserId': currentUserId,
			})
		},
		GetTransactions: async (filters?: TransactionFilter): Promise<ApiResult<PagedData<TransactionData>>> => {
			return get(`${CARDS_API_URL}/transactions`, { ...filters })
		},
		GetAuthorizations: async (
			page: number,
			filters?: TransactionFilter
		): Promise<ApiResult<PagedData<TransactionData>>> => {
			return get(`${CARDS_API_URL}/authorizations`, { page: page, ...filters })
		},
		Freeze: async (cardId: string): Promise<ApiResult<BasicCardOperationResponse>> => {
			return put(`${CARDS_API_URL}/${cardId}/set-card-status`, {
				reason: 'Frozen from client by user: ' + getCurrentUserId(),
				cardStatus: 'BlockedByCustomer',
			})
		},
		ReportStolen: async (cardId: string): Promise<ApiResult<BasicCardOperationResponse>> => {
			return put(`${CARDS_API_URL}/${cardId}/set-card-status`, {
				reason: 'Reported stolen from client by user: ' + getCurrentUserId(),
				cardStatus: 'Stolen',
			})
		},
		Close: async (cardId: string): Promise<ApiResult<BasicCardOperationResponse>> => {
			return put(`${CARDS_API_URL}/${cardId}/set-card-status`, {
				reason: 'Closed by user: ' + getCurrentUserId(),
				cardStatus: 'Closed',
			})
		},
		Unfreeze: async (cardId: string): Promise<ApiResult<BasicCardOperationResponse>> => {
			//return put(`${CARDS_API_URL}/${cardId}/freeze`, {})
			return put(`${CARDS_API_URL}/${cardId}/set-card-status`, {
				reason: 'Unfrozen from client by user: ' + getCurrentUserId(),
				cardStatus: 'Active',
			})
		},
		CreateDispute: async (cardId: string, disputeData: DisputeRequest): Promise<ApiResult<any>> => {
			return post(`${DISPUTES_API_URL}/cards/${cardId}`, disputeData)
		},
		GetDispute: async (cardId: string): Promise<ApiResult<any>> => {
			return get(`${DISPUTES_API_URL}/cards/${cardId}`)
		},
		SearchDisputes: async (cardId: string): Promise<ApiResult<any>> => {
			return get(`${DISPUTES_API_URL}/cards/${cardId}`)
		},
	}

	readonly Accounts = {
		OpenNewAccount: async (idempotencyKey: string): Promise<ApiResult<any>> => {
			return post(
				`${ACCOUNTS_API_URL}/`,
				{
					withIban: false,
					name: 'First account',
					accountType: AccountType.Card,
					currency: 'BGN',
					userId: getCurrentUserId(),
				},
				{
					'X-Idempotency-Key': idempotencyKey,
				}
			)
		},
		GetActiveAccounts: async (): Promise<ApiResult<PagedData<AccountData>>> => {
			const currentUserId = getCurrentUserId().toString()

			const replaceNumberValuesWithString = (input: string) => {
				return input.replace(/"id":(\d+),/g, '"id":"$1",')
			}

			return get(
				`${ACCOUNTS_API_URL}/`,
				{ 'Filters.UserId': currentUserId, 'Filters.Status': 'Active' },
				replaceNumberValuesWithString
			)
		},
		GetAccountDataById: async (id: string): Promise<ApiResult<AccountData>> => {
			return get(`${ACCOUNTS_API_URL}/${id}`)
		},
		GetTransactions: async (
			accountId: string,
			page: number,
			filters?: TransactionFilter
		): Promise<ApiResult<PagedData<TransactionData>>> => {
			return get(`${ACCOUNTS_API_URL}/${accountId}/transactions`, {
				...filters,
				AccountId: accountId,
				'Filters.OrderBy': 'TransactionDate',
			})
		},
		GetBalance: async (
			accountId: string,
			filters?: TransactionFilter
		): Promise<ApiResult<PagedData<TransactionData>>> => {
			return get(`${ACCOUNTS_API_URL}/balance`, {
				...filters,
				AccountId: accountId,
			})
		},
		GenerateAccountStatements: async (accountId: string, from: Date, to: Date): Promise<ApiResult<any>> => {
			return post(
				`${ACCOUNTS_API_URL}/${accountId}/statement`,
				{ fromDate: from.toISOString(), toDate: to.toISOString() },
				{
					'X-Idempotency-Key': `Idem-${Math.ceil(Math.random() * 100000)}-${Math.ceil(Math.random() * 100000)}`,
				}
			)
		},
		GetAccountStatements: async (accountId: string, statementId: string): Promise<ApiResult<any>> => {
			return get<any>(`${ACCOUNTS_API_URL}/${accountId}/statement/${statementId}`)
		},
		Search: async (iban: string): Promise<ApiResult<any>> => {
			return get(`${ACCOUNTS_API_URL}/search`, {
				tenantId: getTenantId(),
				iban: iban,
			})
		},
	}

	readonly Users = {
		Login: async (request: LoginData): Promise<ApiResult<LoginResponse>> => {
			const filledRequest: LoginRequest = {
				userName: request.userName,
				password: request.password,
				realmName: REALM_NAME,
				clientSecret: CUSTOMER_API,
				loginType: LoginType.email,
				clientId: 'customer-api',
				deviceId: null,
			}

			return post<LoginRequest, LoginResponse>(`${USERS_API_URL}/login`, filledRequest).then((response) => {
				if (response === undefined) {
					return {
						success: false,
						code: 400,
						error: 'No response',
					}
				}

				if (response.success) {
					const data = response.data as LoginResponse
					localStorage.setItem('token', data.accessTokenResponse?.access_token as string)
					const token = data.accessTokenResponse!.access_token as string
					const decoded = jwtDecode(token)
					console.log('Decoded token', decoded)

					setCurrentUserId(data.userId as string)
					setTenantId(data.tenantId as string)

					return {
						success: true,
						code: 200,
						data: data,
					}
				}

				const error =
					response.error === 'InvalidUserCredentials' ? 'Invalid username or password' : response.error

				return {
					success: false,
					code: response.code,
					error: error,
				}
			})
		},
		InitiateForgotPassword: async (email: string): Promise<ApiResult<InitiateChangePasswordResponse>> => {
			const data = {
				email: email,
				tenantName: REALM_NAME,
			}

			return post(`${USERS_API_URL}/initiate-password-reset`, data)
		},
		ResetPassword: async (
			otp: string,
			tempPassword: string,
			newPassword: any,
			tokenId: string,
			userId: string,
			tenantId: string
		): Promise<ApiResult<ChangePasswordResponse>> => {
			const data = {
				otp: otp,
				temporaryPassword: tempPassword,
				newPassword: newPassword,
				confirmPassword: newPassword,
				resetPasswordTokenId: tokenId,
			}

			const extraHeaders = {
				//"x-user-id" : userId,
				'x-realm-id': tenantId,
			}

			return post(`${USERS_API_URL}/change-password`, data, extraHeaders)
		},
		GetRoles: async (): Promise<any> => {
			return get(`${USERS_API_URL}/role-mappings`, null)
		},
		GetUsers: async (params?: any): Promise<ApiResult<PagedData<UserData>>> => {
			return get(`${USERS_API_URL}/`, { ...params, UserUserId: '123123' }) //, headersWithRealmId())
		},
		GetUser: async (userId: string, params?: any): Promise<ApiResult<PagedData<UserData>>> => {
			return get(`${USERS_API_URL}/`, { ...params, UserUserId: userId })
		},
		GetCustomers: async (): Promise<any> => {
			return get(`${USERS_API_URL}/customers`, null)
		},
		GetCustomerProfileImage: async (): Promise<any> => {
			return get(`${USERS_API_URL}/customers/profile-image`, null)
		},
	}

	readonly Corporations = {
		GetCorporations: async (params?: any): Promise<ApiResult<PagedData<Corporation>>> => {
			return get(`${CORPORATIONS_API_URL}`, params)
		},
		GetCorporationMembers: async (corporationId: string, filters?: any): Promise<ApiResult<PagedData<CorporationMember>>> => {
			if (!filters || !('OrderBy' in filters)) {
				filters = { ...filters, OrderBy: 'createdOn' }
			}

			if (!filters || !('OrderByDescending' in filters)) {
				filters = { ...filters, OrderByDescending: true}
			}

			return get(`${CORPORATIONS_API_URL}/${corporationId}/members`, filters)
		},
	}

	readonly Tenant = {
		GetCardPrograms: async (): Promise<ApiResult<PagedData<CardProgramResponse>>> => {
			return get(`${TENANT_API_URL}/${getTenantId()}/card-programs`)
		},
	}

	//https://users.easypsit.eu/api/users/logout
	Logout = async (): Promise<ApiResult<any>> => {
		const promise = get<ApiStatus>(`${USERS_API_URL}/logout`, null)
		clearSession()
		localStorage.removeItem('token')
		return promise
	}
}

function headersWithRealmId(headers?: any | null) {
	if (headers === undefined || headers === null) {
		return { 'x-realm-id': getTenantId() }
	}

	return { ...headers, 'x-realm-id': getTenantId() }
}

function headersWithRealmIdAndUserId(headers: any | undefined | null) {
	if (headers === undefined || headers === null) {
		return { 'x-realm-id': getTenantId(), 'x-user-id': getCurrentUserId() }
	}

	return { ...headers, 'x-realm-id': getTenantId(), 'x-user-id': getCurrentUserId() }
}

const API = new Api()
export default API
