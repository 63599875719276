import PageHeader from 'components/elements/PageHeader'
import BasePage from 'components/pages/BasePage'
import React, {useEffect, useState} from 'react'
import UserTable from 'components/elements/UserTable'
import {Col} from 'react-bootstrap'
import LinkButton from 'components/elements/LinkButton'
import {newUserRoute} from 'Routes'
import iconPlus from '../../assets/imgs/icons/credit-card-plus.svg'
import Select from 'react-select'
import {CircleSpinnerOverlay} from 'react-spinner-overlay'
import API from '../../api/API'
import {Corporation} from '../../api/Models'
import CorporationMemberTable from "../elements/CorporationMemberTable";

function HeaderControls() {
    return (
        <>
            <Col className="me-auto"></Col>
            <Col>
                <div className="page-nav">
                    <LinkButton to={newUserRoute} icon={iconPlus} label="New User" type="primary"/>
                </div>
            </Col>
        </>
    )
}

function UserManagementPage() {
    const [isLoading, setIsLoading] = useState(false)
    const [corporations, setCorporations] = useState<Corporation[] | null>(null)
    const [selectedCorporation, setSelectedCorporation] = useState<Corporation | null>(null)
    useEffect(() => {
        API.Corporations.GetCorporations()
            .then((result) => {
                if (result.success) {
                    setCorporations(result.data?.data ?? [])
                    if (result.data!.data.length === 0) {
                        setSelectedCorporation(null)
                    } else {
                        setSelectedCorporation(result.data!.data[0])
                    }
                } else {
                    setCorporations([])
                    setSelectedCorporation(null)
                }
            })
            .finally(() => setIsLoading(false))
    }, [])

    return (
        <>
            {isLoading && <CircleSpinnerOverlay/>}
            <PageHeader title={'User Access and Controls '} controls={<HeaderControls/>}/>
            <BasePage>
                {corporations == null ? (
                    <></>
                ) : (
                    <>
                        <Select
                            options={corporations}
                            getOptionLabel={(c: Corporation) => c.name}
                            getOptionValue={(c: Corporation) => c.id}
                            value={selectedCorporation}
                            onChange={c => setSelectedCorporation(c)}
                        />
                        {!!selectedCorporation && <CorporationMemberTable corporation={selectedCorporation.id}/>}
                    </>
                )}
            </BasePage>
        </>
    )
}

export default UserManagementPage
