import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { NewCardReviewDTO } from 'dtos/DTOs'
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { toast } from 'react-toastify'
import { defaultToastOptions } from 'utils'
import API from 'api/API'
import { CardType, CreateCardRequest } from 'api/Models'
import Label from 'components/elements/Label'
import Value from 'components/elements/Value'
import BasePage from 'components/pages/BasePage'
import { Button } from 'react-bootstrap'
import PageHeader from '../elements/PageHeader'
import { cardManagementRoute, dashboardRoute } from '../../Routes'
import successAnimation from 'assets/imgs/chargeback-success.gif'

function GetCreateCardRequest(state: NewCardReviewDTO): CreateCardRequest {
	return {
		userId: state.user.id,
		accountId: state.account.id,
		alias: state.alias,
		type: state.cardType.value as CardType,
		embossName: state.embossName,
		embossLine4: state.embossLine4,
		cardProgram: state.program,
		creditLimit: state.credit || 0,
		cardPlasticRequest: state.cardType.value === 'Virtual' ? undefined : state.address
	}
}

function NewCardReviewPage() {
	const location = useLocation()
	const state = location.state as NewCardReviewDTO
	const [busy, setBusy] = useState(false)
	const [hasAcceptedFees, setHasAcceptedFees] = useState(false)
	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
	const [success, setSuccess] = useState(false)

	const submit = async () => {
		setBusy(true)
		try {
			const cardsResult = await API.Cards.GetCards({ 'filters.accountId': state.account.id })

			if (!cardsResult.success) {
				return
			}

			let result

			if (cardsResult.data!.totalCount > 0) {
				result = await API.Cards.IssueCard(GetCreateCardRequest(state))
			} else {
				result = await API.Cards.CreateCard(GetCreateCardRequest(state))
			}

			if (result.success) {
				//toast.success('Card created successfully', defaultToastOptions())
				setSuccess(true)
			} else {
				toast.error(`Failed to create card ${result.error}`, defaultToastOptions())
			}
		} catch (e) {
			console.error(e)
		} finally {
			setBusy(false)
		}
	}

	return (
		<>
			{busy && <CircleSpinnerOverlay />}
			<PageHeader title="Order New Card" backLink="/card-management/new-card" backState={state} />
			<BasePage>
				{!success ? (
					<div className="col-lg-4 offset-lg-4">
						<div className="row align-items-center">
							<div className="col me-auto">
								<h4>Order review</h4>
							</div>
							<div className="col-auto">
								<Link
									to={'/card-management/new-card'}
									className="btn btn-outline-primary"
									state={state}
									style={{minWidth:"unset"}}
								>
									Edit details
								</Link>
							</div>
						</div>

						<ul className="form-review">
							<li>
								<Label>Account</Label>
								<Value bold>{state.account.name}</Value>
							</li>
							<li>
								<Label>User</Label>
								<Value bold>{state.user.name}</Value>
							</li>
							<li>
								<Label>Card type</Label>
								<Value bold>{state.cardType.name}</Value>
							</li>
							<li>
								<Label>Alias</Label>
								<Value bold>{state.alias}</Value>
							</li>
							<li>
								<Label>Emboss Name</Label>
								<Value bold>{state.embossName}</Value>
							</li>
							{
								<li>
									<Label>Emboss Line 4</Label>
									<Value bold>{state.embossLine4}</Value>
								</li>
							}
							<li>
								<Label>Program</Label>
								<Value bold>{state.program}</Value>
							</li>
							<li>
								<Label>Credit</Label>
								<Value bold>{state.credit || 0}</Value>
							</li>
							<li>
								<hr />
							</li>
							<li>
								<hr />
							</li>
							{(state.cardType.value !== "Virtual" && state.address) && (
								<li>
									<Label>Delivery address</Label>
									<Value>{state.address.houseNumber} {state.address.street}, {state.address.city}, {state.address.countryCode}, {state.address.postcode}</Value>
								</li>
							)}
						</ul>

						<div className="form-group">
							<div className="checkbox">
								<input
									id="terms1"
									type="checkbox"
									checked={hasAcceptedFees}
									onChange={(e) => setHasAcceptedFees(e.target?.checked === true)}
								/>
								<label htmlFor="terms1">
									This will be the delivery and fees info Lorem ipsum dolor sit amet, consectetur
									adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
									ex ea commodo consequat.
								</label>
							</div>
						</div>
						<div className="form-group">
							<div className="checkbox">
								<input
									type="checkbox"
									id="terms2"
									checked={hasAcceptedTerms}
									onChange={(e) => setHasAcceptedTerms(e.target?.checked === true)}
								/>
								<label className="label-link" htmlFor="terms2">
									I agree to <Link to="">Terms &amp; Conditions</Link>
								</label>
							</div>
						</div>
						<div className="form-action">
							<Button
								className="btn-submit"
								onClick={submit}
								disabled={!(hasAcceptedFees && hasAcceptedTerms)}
							>
								Submit
							</Button>
						</div>
					</div>
				) : (
					<div className="congratulations d-flex flex-column">
						<img className="animation mt-5" src={successAnimation} alt="success" />
						<h2 className="mt-1">Congratulations.</h2>
						<span className="mt-4 mb-5">
							Return to <Link to={cardManagementRoute}>Card Management</Link> | <Link to={dashboardRoute}>Dashboard</Link>
						</span>
					</div>
				)}
			</BasePage>
		</>
	)
}

export default NewCardReviewPage
