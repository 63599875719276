import iconInfo from "assets/imgs/icons/transaction-info.svg";
import React, {useEffect, useState} from "react";
import {getTransactionColor, mapCorporationMemberRows} from "utils";
import API from "api/API";
import {CorporationMemberRow } from "api/Models";
import ExtendingButton from "components/elements/ExtendingButton";
import Table, { DateTimeCell, PhoneCell, TagCell, TextCell} from "components/elements/Table";

interface UserTableProps {
    //filters: TransactionFilter
    corporation: string
    pageSize?: number
    noFetch?: boolean
}

function CorporationMemberTable(props: UserTableProps) {
    const tableColumns = [
        "User Id",
        "Name",
        "Phone",
        "Email",
        "Role",
        "Status",
        "Last Login",
        "Settings",
        "Actions",
    ]

    const [users, setUsers] = useState<CorporationMemberRow[]>([])
    const [page, setPage] = useState(1)
    const [totalPageCount, setTotalPageCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const pageSize = props.pageSize ?? 10;

    useEffect(() => {
        setLoading(true)

        if (props.noFetch) {
            return;
        }

        const skip = (page - 1) * pageSize;

        API.Corporations.GetCorporationMembers(props.corporation, {"OffsetPaging.Skip": skip, "OffsetPaging.Take": pageSize})
            .then(result => {
                if (result.success && result.data) {
                    setTotalPageCount(Math.ceil(result.data.totalCount / result.data.pageSize))
                    setUsers(mapCorporationMemberRows(result.data.data))
                }
            }).finally(() => setLoading(false))

    }, [props, page, pageSize])
    //User ID
    // User name
    // Linked cards
    // Mobile phone
    // Email
    // Role
    // Status
    // Last login (date, time, IP)
    // Settings shortcut
    // Actions: Freeze/Unfreeze; Remove

    const rowRenderer = (row: CorporationMemberRow) => {
        return [
            <TextCell text={row.userId}/>,
            <TextCell text={row.name}/>,
            <PhoneCell phone={row.phoneNumber}/>,
            <TextCell text={row.email}/>,
            <TextCell text={row.roles}/>,
            <TagCell text={row.status} color={getTransactionColor(row.status)}/>,
            <DateTimeCell date={row.lastLogin}/>,
            <ExtendingButton to="transaction-details" icon={iconInfo} label="Settings" type="outline-primary"/>,
            <div>
                Action buttons go here
            </div>
        ]
    }

    return <Table columns={tableColumns}
                  loading={loading}
                  page={page}
                  totalPageCount={totalPageCount}
                  onPageChange={setPage}
                  data={users}
                  rowRenderer={rowRenderer}/>
}

export default CorporationMemberTable;