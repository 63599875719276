import {
	CorporationMember,
	CorporationMemberRow,
	PersonNames, PhoneNumber,
	TransactionData,
	TransactionRow,
	UserData,
	UserRow,
} from './api/Models'
import { PhoneCodes } from './dtos/DTOs'
import { Flip } from 'react-toastify'

export function formatCurrency(value: number, currency: string | null) {
	if (!currency) {
		return value.toString()
	}

	if (Number.isNaN(value)) {
		return 'NaN'
	}

	try {
		return Intl.NumberFormat(navigator.language, { style: 'currency', currency: currency }).format(value)
	} catch {
		return value.toString()
	}
}

export function formatPhone(phone: string): string {
	if (phone.length < 2) {
		return phone
	}

	const potentialCode = phone.substring(0, 2)

	if (potentialCode in PhoneCodes) {
		return `+${PhoneCodes[potentialCode]} ${phone.substring(2)}`
	}

	return phone
}

export function formatPhoneFromObject(phone: PhoneNumber): string {

	return `+${PhoneCodes[phone.countryCode]} ${phone.value}`
}

export function splitCardPan(pan: string | null | undefined): string {
	if (pan === undefined || pan === null) {
		return '**** **** **** ****'
	}

	if (pan.length !== 16) {
		return pan
	}

	return pan.match(/.{1,4}/g)?.join(' ') ?? pan
}

export function getCardArtClass(cardDesign: number) {
	//const design = (cardDesign % 4) + 1
	return 'cd-01'
}

export function getTransactionColor(status: string) {
	if (!status) {
		return 'pending'
	}

	switch (status.toLowerCase()) {
		case 'approved':
			return 'approved'
		case 'declined':
			return 'declined'
		default:
			return 'pending'
	}
}

export function mapTransactionRows(array: TransactionData[]): TransactionRow[] {
	return array.map((t) => ({
		id: t.id,
		merchant: t.description,
		transactionType: t.transactionType,
		date: new Date(t.dateCreatedUtc),
		card: t.obscuredPan,
		amount: t.amountOriginalTransaction.toString(),
		currency: t.currencyOriginalTransaction, // "USD",
		status: t.transactionStatus,
	}))
}

export function mapUserRows(array: UserData[]): UserRow[] {
	return array.map((c) => ({
		clientNumber: c.clientNumber,
		createdOn: new Date(c.createdOn),
		status: c.status,
		registrationStatus: c.registrationStatus,
		nickname: c.nickname,
		email: c.email,
		phoneNumber: c.phoneNumber,
		referenceNumber: c.referenceNumber,
	}))
}

export function mapCorporationMemberRows(array: CorporationMember[]): CorporationMemberRow[] {

	return array.map((c) => ({
		userId: c.userId,
		status: c.status,
		name: mergePersonNames(c.personNames),
		email: c.email,
		phoneNumber: formatPhoneFromObject({countryCode: c.phoneNumberCountryCode, value:c.phoneNumberValue}),
		roles: c.roles,
		lastLogin: new Date(c.lastLoginTime),
	}))
}

function mergePersonNames(personNames: PersonNames[]) {
	if(!personNames) {
		return ""
	}

	return personNames
		.map((p) => `${p.first} ${p.middle} ${p.last}`)
		.join(', ')
		.replace('  ', '')
}

export function zeroPad(num: number, places: number) {
	return String(num).padStart(places, '0')
}

export function defaultToastOptions(): any {
	return {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
		transition: Flip,
	}
}

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str: string) {
	let hash = 0
	const len = str.length

	for (let i = 0; i < len; i++) {
		let chr = str.charCodeAt(i)
		hash = (hash << 5) - hash + chr
		hash |= 0
	}
	return hash
}

/**
 * Password must be adhered to the following policy:
 *
 *     Minimum password length: 12 characters
 *     Maximum password length: 64 characters
 *     At least one uppercase letter
 *     At least one lowercase letter
 *     At least one digit
 *     At least one special character
 *     The password must not contain the username or email address
 *     A password change is required every 60 days
 *     Passwords must not be part of the last 3 used passwords
 */

export function ValidatePasswordLength(password: string | null): [boolean, string] {
	if (password === null) {
		return [false, 'passwordIsNull']
	}

	if (password.length < 12) {
		return [false, 'password too short']
	}

	if (password.length > 64) {
		return [false, 'password too long']
	}

	return [true, '']
}

export function ValidatePasswordLowercase(password: string | null): [boolean, string] {
	if (password === null) {
		return [false, 'passwordIsNull']
	}

	if (!containsLowerCaseLetter(password)) {
		return [false, 'password must contain lower case letter']
	}

	return [true, '']
}

export function ValidatePasswordUpperCase(password: string | null): [boolean, string] {
	if (password === null) {
		return [false, 'passwordIsNull']
	}

	if (!containsUpperCaseLetter(password)) {
		return [false, 'password must contain uppercase letter']
	}

	return [true, '']
}

export function ValidatePasswordDigit(password: string | null): [boolean, string] {
	if (password === null) {
		return [false, 'passwordIsNull']
	}

	if (!containsDigit(password)) {
		return [false, 'password must contain a digit']
	}

	return [true, '']
}

export function ValidatePasswordSpecialCharacter(password: string | null): [boolean, string] {
	if (password === null) {
		return [false, 'passwordIsNull']
	}

	if (!containsSpecialCharacter(password)) {
		return [false, 'password must contain a special character']
	}

	return [true, '']
}

export function ValidatePassword(password: string | null): [boolean, string] {
	if (password === null) {
		return [false, 'passwordIsNull']
	}

	let [result, error] = ValidatePasswordLength(password)

	if (!result) {
		return [result, error]
	}

	;[result, error] = ValidatePasswordLowercase(password)

	if (!result) {
		return [result, error]
	}

	;[result, error] = ValidatePasswordUpperCase(password)

	if (!result) {
		return [result, error]
	}

	;[result, error] = ValidatePasswordDigit(password)

	if (!result) {
		return [result, error]
	}

	;[result, error] = ValidatePasswordSpecialCharacter(password)

	if (!result) {
		return [result, error]
	}

	return [true, '']
}

function containsUpperCaseLetter(str: string) {
	return new RegExp('[A-Z]').test(str)
}

function containsLowerCaseLetter(str: string) {
	return new RegExp('[a-z]').test(str)
}

function containsDigit(str: string) {
	return new RegExp('[0-9]').test(str)
}

function containsSpecialCharacter(str: string) {
	return new RegExp('.*[^\\w\\s]').test(str)
}

export type ValidationMethod = () => [boolean, string]

export function RunValidations(methods: ValidationMethod[]): [boolean, string] {
	for (let i = 0; i < methods.length; i++) {
		const [valid, msg] = methods[i]()

		if (!valid) {
			return [valid, msg]
		}
	}

	return [false, '']
}

export function RunAllValidations(methods: ValidationMethod[]): [boolean, string][] {
	const failedValidation: [boolean, string][] = []

	for (let i = 0; i < methods.length; i++) {
		const [valid, msg] = methods[i]()

		if (!valid) {
			failedValidation.push([valid, msg])
		}
	}

	return failedValidation
}

export function CardStatusPrettify(status?: string | null) {
	if (!status) {
		return 'Unknown'
	}

	switch (status) {
		case 'BlockedByConsumer':
			return 'Frozen'
	}

	return status as string
}
